// To add a new icon
// 1. Download it on lucide.dev or export from figma if homemade
// 2. Remove width and height attributes on svg tag in the file
// 3. Add here and order alphabetically please
// 4. Add the name in options.json

import Address from './address.svg';
import ArrowDown from './arrow-down.svg';
import ArrowLeft from './arrow-left.svg';
import ArrowRight from './arrow-right.svg';
import ArrowRightCircle from './arrow-right-circle.svg';
import ArrowUpRight from './arrow-up-right.svg';
import Blocks from './blocks.svg';
import Bold from './bold.svg';
import BookOpen from './book-open.svg';
import Box from './box.svg';
import ChartPie from './chart-pie.svg';
import Code from './code.svg';
import Code2 from './code-2.svg';
import Coffee from './coffee.svg';
import CornerUpRight from './corner-up-right.svg';
import CustomCheckbox from './custom-checkbox.svg';
import File from './file.svg';
import Grid2x2Check from './grid-2x2-check.svg';
import Hexagon from './hexagon.svg';
import Italic from './italic.svg';
import Link from './link.svg';
import Link2 from './link-2.svg';
import List from './list.svg';
import ListOrdered from './list-ordered.svg';
import LogIn from './log-in.svg';
import Mail from './mail.svg';
import Menu from './menu.svg';
import Moon from './moon.svg';
import MoreHorizontal from './more-horizontal.svg';
import Move3d from './move-3d.svg';
import Quote from './quote.svg';
import Repeat2 from './repeat-2.svg';
import ScanEye from './scan-eye.svg';
import Send from './send.svg';
import ShieldCheck from './shield-check.svg';
import Sparkles from './sparkles.svg';
import Strikethrough from './strikethrough.svg';
import Sun from './sun.svg';
import Tag from './tag.svg';
import Target from './target.svg';
import ThumbsUp from './thumbs-up.svg';
import ToyBrick from './toy-brick.svg';
import TrustblockLogoFull from './trustblock-logo-full.svg';
import TrustblockLogoMark from './trustblock-logo-mark.svg';
import Twitter from './twitter.svg';
import Usb from './usb.svg';
import User from './user.svg';
import X from './x.svg';
import XTwitter from './x-twitter.svg';
import Youtube from './youtube.svg';
import ZoomIn from './zoom-in.svg';

const ICONS = {
  Address,
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  ArrowRightCircle,
  ArrowUpRight,
  Blocks,
  Bold,
  BookOpen,
  Box,
  ChartPie,
  Code,
  Code2,
  Coffee,
  CornerUpRight,
  CustomCheckbox,
  File,
  Grid2x2Check,
  Hexagon,
  Italic,
  Link,
  Link2,
  List,
  ListOrdered,
  LogIn,
  Mail,
  Menu,
  Moon,
  MoreHorizontal,
  Move3d,
  Quote,
  Repeat2,
  ScanEye,
  Send,
  ShieldCheck,
  Sparkles,
  Strikethrough,
  Sun,
  Tag,
  Target,
  ThumbsUp,
  ToyBrick,
  TrustblockLogoFull,
  TrustblockLogoMark,
  Twitter,
  Usb,
  User,
  X,
  XTwitter,
  Youtube,
  ZoomIn,
};

export const getIconComponent = (name) => ICONS[name];
